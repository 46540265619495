import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { AuthenticationService } from './authentication';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private authService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clone = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.authService.accessToken}`),
    });

    return next.handle(clone).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.authService.logout();
            return throwError(error);
          }
        },
      ),
    );
  }
}
