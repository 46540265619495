import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '@env/environment';
import { CoreModule, AuthenticationService } from '@app/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Error404Component } from './views/error404/error404.component';
import { NoAccessComponent } from './views/no-access/no-access.component';

import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { HomeComponent } from './views/home/home.component';
import { ImplicitRedirectComponent } from './views/implicit-redirect/implicit-redirect.component';
import { MatSelectModule } from '@angular/material/select';
registerLocaleData(localeRu, 'ru');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    OAuthModule.forRoot(),
    NgxsModule.forRoot([]),
    !environment.production ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
    NgxsRouterPluginModule.forRoot(),
    MatSelectModule,
  ],
  declarations: [AppComponent, Error404Component, NoAccessComponent, HomeComponent, ImplicitRedirectComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthenticationService) => () => authService.init(),
      deps: [AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
