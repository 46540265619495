import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { authCodeFlowConfig } from './authentication.config';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public isLoggedIn$: BehaviorSubject<boolean>;

  constructor(private oauthService: OAuthService, private location: Location) {
    this.isLoggedIn$ = new BehaviorSubject(this.oauthService.hasValidAccessToken());

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        if (event.type === 'invalid_nonce_in_state') {
          this.logout();
        }
      }
    });

    window.addEventListener('storage', event => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      if (!this.oauthService.hasValidAccessToken()) {
        this.logout();
      }
    });
  }

  public get isLoggedIn(): boolean {
    return this.isLoggedIn$.value;
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  init() {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      this.isLoggedIn$.next(this.oauthService.hasValidAccessToken());
      Promise.resolve();
    });
  }

  login() {
    const path = this.location.path();
    this.oauthService.initLoginFlow(path || '/');
  }

  logout() {
    this.oauthService.logOut();
    this.isLoggedIn$.next(false);
  }
}
