// `.env.ts` is generated by the `npm run env` command
import { env } from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  useradminEndpoint: 'https://bim.pik.ru/auth/useradmin',
  tokenIssuer: 'https://test.auth.pik-digital.ru',
  oauthClientId: 'bds_spa',
  oauthScope: 'bds_api',
  fmEndpoint: 'https://bim.pik.ru/fm/FM/v1',
  baseHref: '',
  defaultLanguage: 'ru-RU',
  supportedLanguages: ['ru-RU'],
  bdsServerUrl: 'https://bds1-api.bimteam.ru/v1',
};
