<div class="error-content">
  <div class="warning-image" [class.info-image]="!data?.isError"></div>
  <mat-dialog-content>
    <ng-container *ngIf="!data || data?.isError">
      <!-- todo: using ngIf-else -->
      <ng-container *ngIf="data && !exception">
        <div>
          {{ 'Server error' | translate }} ({{ 'code' | translate }}: <span class="error-code">{{ data.code }}</span
          >)
        </div>
      </ng-container>
      <ng-container *ngIf="!data && !exception">
        {{ 'Unknown error' | translate }}
      </ng-container>
      <ng-container *ngIf="exception">
        {{ exception?.Message }}
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!data?.isError">{{ data.text }}</ng-container>
  </mat-dialog-content>
</div>
<mat-dialog-actions class="dialog-buttons">
  <button mat-button mat-raised-button mat-dialog-close color="primary">ОK</button>
</mat-dialog-actions>
