import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { AuthenticationService } from '@app/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="isLoggedIn">
      <router-outlet></router-outlet>
    </ng-container>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLoggedIn: boolean;

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    private translateService: TranslateService,
    private oauthService: OAuthService,
  ) {
    // Setup translations
    this.translateService.addLangs(environment.supportedLanguages);
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  ngOnInit(): void {
    this._subscribeToLogin();
  }

  private _subscribeToLogin() {
    const loginSubscription = this.authService.isLoggedIn$.subscribe(async isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        this._redirectToUrl(this.oauthService.state);
      }
    });
  }

  private _redirectToUrl(state: string) {
    if (state && state !== 'undefined' && state !== 'null') {
      let stateUrl = state;
      if (stateUrl.startsWith('/') === false) {
        stateUrl = decodeURIComponent(stateUrl);
      }

      this.router.navigateByUrl(stateUrl);
    }
  }
}
