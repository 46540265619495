import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';

@Component({
  template: '<h1>redirecting..</h1>',
})
export class ImplicitRedirectComponent implements OnInit {
  constructor(private oauthService: OAuthService, private router: Router, private authService: AuthenticationService) {}

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.router.navigateByUrl('/');
    });
  }
}
