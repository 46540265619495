import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core';
import { Error404Component } from '@views/error404';
import { NoAccessComponent } from '@views/no-access';
import { ImplicitRedirectComponent } from './views/implicit-redirect/implicit-redirect.component';

const routes: Routes = [
  {
    path: 'error',
    canActivate: [AuthGuard],
    component: Error404Component,
    data: { title: 'Error' },
  },
  {
    path: 'noaccess',
    canActivate: [AuthGuard],
    component: NoAccessComponent,
  },
  {
    path: 'implicit-redirect',
    component: ImplicitRedirectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./views/bds/bds.module').then(m => m.BdsModule),
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
