<div class="container">
  <div class="error-header">404</div>
  <div class="error-content">
    <span class="error-content-header">Это ошибка 404. Страница не найдена</span>
    <span
      >Запрашиваемая вами страница не найдена. Пожалуйста, воспользуйтесь меню слева, чтобы перейти к нужному
      разделу.</span
    >
  </div>
</div>
