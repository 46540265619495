import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AuthGuard } from './authentication';
import { TokenInterceptor } from './token.interceptor';
import { HttpService } from './http';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, MatDialogModule],
  providers: [
    AuthGuard,
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
