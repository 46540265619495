import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@env/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.tokenIssuer,
  clientId: environment.oauthClientId,
  scope: environment.oauthScope,
  redirectUri: window.location.origin + '/implicit-redirect',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  clearHashAfterLogin: false,
  showDebugInformation: true,
};

export const redirectUri = '/implicit-redirect';
